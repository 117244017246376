import PropTypes from 'prop-types';
import React from 'react';
import Page404 from '../../components/errors/404';
import Page500 from '../../components/errors/500';

const ErrorContainer = ({ statusCode }) => {
  switch (statusCode) {
    case 404:
      return <Page404 />;
    case 500:
      return <Page500 />;
    default:
      return <Page500 />;
  }
};

export default ErrorContainer;

ErrorContainer.propTypes = {
  statusCode: PropTypes.number,
};

ErrorContainer.defaultProps = {
  statusCode: 500,
};
