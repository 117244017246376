import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import iconStyles from './icon.module.scss';
import iconFontStyles from './iconFont.module.scss';
import types from './iconFont.json';

const Icon = ({ type, increaseClickableArea, className, onClick, xl, lg, md, sm }) => {
  return (
    <i
      className={cn(
        iconStyles.root,
        iconFontStyles.icon,
        iconFontStyles[type],
        {
          [iconStyles.clickableArea]: increaseClickableArea,
          [iconStyles.extraLarge]: xl,
          [iconStyles.large]: lg,
          [iconStyles.medium]: md,
          [iconStyles.small]: sm,
        },
        className
      )}
      onClick={onClick}
    />
  );
};

export default Icon;

Icon.propTypes = {
  className: PropTypes.string,
  increaseClickableArea: PropTypes.bool,
  onClick: PropTypes.func,
  xl: PropTypes.bool,
  lg: PropTypes.bool,
  md: PropTypes.bool,
  sm: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(types).map(m => `icon-${m}`)),
};

Icon.defaultProps = {
  className: undefined,
  increaseClickableArea: true,
  onClick: () => {},
  xl: false,
  lg: false,
  md: false,
  sm: false,
  type: undefined,
};
