import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import cn from 'classnames';
import NextImage from 'next/image';
import imageStyles from './image.module.scss';
import { buildImageStorageUrl, storageUrl } from '../../utils/imageUtils';

const PLACEHOLDER_SRC = buildImageStorageUrl('/common/image-placeholder.png').replace(storageUrl(), '');

const ImageComponent = ({
  quality,
  src,
  title,
  lazy,
  layout,
  onClick,
  objectFit,
  objectPosition,
  unoptimized,
  loadFromStorage,
  placeholderLg,
  placeholderClassName,
  priority,
  className,
  preloader,
  useCDN,
  width,
  height,
  sizes,
}) => {
  const [showFallback, setShowFallback] = useState(!src);
  const [loaded, setLoaded] = useState(false);
  const [innerSizes, setInnerSizes] = useState();
  const imageRef = useRef();

  const buildUrl = () => {
    if (showFallback) {
      return PLACEHOLDER_SRC;
    }

    let imageSrc = !showFallback && src ? src : '';
    imageSrc = loadFromStorage ? buildImageStorageUrl(src) : imageSrc;
    if (useCDN) {
      const sUrl = storageUrl();
      imageSrc = imageSrc.replace(sUrl, '');
    }
    return imageSrc;
  };

  useEffect(() => {
    setInnerSizes(Math.max(imageRef.current.offsetWidth, imageRef.current.offsetHeight));
  });

  const onLoad = () => {
    // eslint-disable-next-line
    setShowFallback(false);
    setLoaded(true);
  };

  const onError = () => {
    setShowFallback(true);
    setLoaded(true);
  };

  const renderLoader = () => {
    return (
      <div className={imageStyles.loader}>
        <ContentLoader
          className={cn(imageStyles.placeholder, placeholderClassName, {
            [imageStyles.placeholderLg]: placeholderLg,
          })}
          backgroundColor="#d2d2d2"
          foregroundColor="#D2D2D2"
          viewBox="0 0 166 151"
        >
          <path d="M 158.936 63.689 c -11.25 45.008 -46.077 69.166 -64.452 79.075 c -7.316 3.946 -15.94 3.958 -23.254 0.009 c -18.22 -9.839 -52.602 -33.827 -64.14 -79.084 c -12.861 -50.442 36.756 -70.18 64.035 -52.112 c 6.464 4.281 17.303 4.508 23.78 0.245 c 28.084 -18.49 76.695 1.199 64.031 51.867 z" />
          <path d="M 94.484 142.764 l 2.374 4.401 l -2.374 -4.401 z m 64.452 -79.075 l -4.851 -1.212 l 4.851 1.212 z M 94.904 11.822 l -2.75 -4.176 l 2.75 4.176 z M 71.23 142.773 l -2.376 4.399 l 2.376 -4.399 z M 7.09 63.689 l 4.844 -1.235 l -4.845 1.235 z m 64.035 -52.112 l -2.761 4.168 l 2.76 -4.168 z m 25.733 135.588 c 18.857 -10.169 55.174 -35.237 66.929 -82.264 l -9.702 -2.424 c -10.745 42.99 -44.082 66.238 -61.974 75.886 l 4.747 8.802 z m 66.929 -82.264 c 6.803 -27.218 -2.834 -47.091 -18.905 -57.3 c -15.656 -9.945 -36.958 -10.337 -52.727 0.045 l 5.498 8.352 c 12.316 -8.108 29.362 -7.9 41.867 0.044 c 12.09 7.68 20.426 22.984 14.565 46.434 l 9.702 2.425 z m -90.182 73.472 c -17.705 -9.56 -50.637 -32.64 -61.67 -75.92 l -9.69 2.471 c 12.042 47.235 47.873 72.132 66.609 82.248 l 4.751 -8.799 z m -61.67 -75.92 c -5.933 -23.265 2.538 -38.539 14.792 -46.285 c 12.695 -8.026 29.785 -8.273 41.637 -0.423 l 5.522 -8.337 C 58.459 -2.81 37.1 -2.222 21.383 7.715 C 5.223 17.931 -4.685 37.748 2.244 64.925 l 9.69 -2.471 z m 80.22 -54.807 c -2.199 1.447 -5.474 2.307 -9.077 2.258 c -3.597 -0.049 -6.93 -0.998 -9.192 -2.496 l -5.522 8.337 c 4.202 2.784 9.52 4.09 14.578 4.158 c 5.052 0.07 10.434 -1.089 14.712 -3.905 l -5.5 -8.352 z m -0.044 130.717 c -5.84 3.15 -12.68 3.156 -18.506 0.01 l -4.751 8.799 c 8.802 4.753 19.212 4.734 28.004 -0.007 l -4.747 -8.802 z" />
        </ContentLoader>
      </div>
    );
  };

  const imageSrc = buildUrl();

  let sizesProp;
  if (sizes) {
    sizesProp = sizes;
  } else if (innerSizes) {
    sizesProp = `${innerSizes}px`;
  }

  const imageProps = showFallback
    ? {
        loading: 'eager',
        objectFit: 'cover',
      }
    : {
        priority,
        unoptimized,
        loading: lazy && !priority ? 'lazy' : 'eager',
        objectFit,
        onError,
        onLoad,
      };

  return (
    <div
      ref={imageRef}
      onClick={showFallback ? onClick : undefined}
      className={cn(imageStyles.root, className, {
        [imageStyles.empty]: showFallback,
        [imageStyles.loading]: !loaded && lazy && !priority,
      })}
    >
      {preloader ? (
        renderLoader()
      ) : (
        <>
          <NextImage
            layout={layout}
            onClick={onClick}
            quality={quality}
            width={width}
            height={height}
            sizes={sizesProp}
            src={imageSrc}
            alt={title}
            objectPosition={objectPosition}
            {...imageProps}
          />
          {!showFallback && renderLoader()}
        </>
      )}
    </div>
  );
};

ImageComponent.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  layout: PropTypes.oneOf(['fixed', 'intrinsic', 'responsive', 'fill']),
  lazy: PropTypes.bool,
  loadFromStorage: PropTypes.bool,
  objectFit: PropTypes.oneOf(['fill', 'contain', 'cover', 'none', 'scale-down']),
  onClick: PropTypes.func,
  placeholderClassName: PropTypes.string,
  placeholderLg: PropTypes.bool,
  preloader: PropTypes.bool,
  priority: PropTypes.bool,
  quality: PropTypes.number,
  sizes: PropTypes.string,
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
  unoptimized: PropTypes.bool,
  useCDN: PropTypes.bool,
  width: PropTypes.number,
  objectPosition: PropTypes.string,
};

ImageComponent.defaultProps = {
  className: undefined,
  height: undefined,
  layout: 'fill',
  lazy: true,
  loadFromStorage: false,
  objectFit: 'cover',
  objectPosition: undefined,
  onClick: () => {},
  placeholderClassName: undefined,
  placeholderLg: false,
  preloader: false,
  priority: false,
  quality: 75,
  sizes: undefined,
  title: '',
  unoptimized: false,
  useCDN: !!process.env.NEXT_PUBLIC_IMAGE_LOADER,
  width: undefined,
};

export default ImageComponent;
