// eslint-disable-next-line
import React, { useContext } from 'react';
import { ConfigContext } from '../contexts/ConfigContext';

const useAuth = (neededPermissions = [], availablePermissions) => {
  const { user } = useContext(ConfigContext);

  if (!neededPermissions) {
    return { can: true };
  }

  const currentUserPermissions = (user && user.permissions) || [];
  const authPermissions = availablePermissions || currentUserPermissions;

  if (!authPermissions.length) {
    return { can: false };
  }

  if (!Array.isArray(neededPermissions)) {
    const can = authPermissions.some(p => p === neededPermissions);
    return { can };
  }

  if (neededPermissions.length === 0) {
    return { can: true };
  }

  const can = neededPermissions.every(p => authPermissions.includes(p));
  return { can };
};

export default useAuth;
