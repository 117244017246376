import React from 'react';
import cn from 'classnames';
import { useQuery, useApolloClient } from '@apollo/client';
import notificationsStyles from './notifications.module.scss';
import { GET_NOTIFICATIONS } from '../../gql/query';
import { useTimeout } from '../../hooks';
import Icon from '../icon/icon';

const Notifications = () => {
  const { data: notificationsData } = useQuery(GET_NOTIFICATIONS);
  const client = useApolloClient();

  useTimeout(() => {
    client.writeQuery({
      query: GET_NOTIFICATIONS,
      data: {
        notifications: [],
      },
    });
  }, 5000);

  return notificationsData && notificationsData.notifications.length > 0 ? (
    <div className={notificationsStyles.root}>
      <div
        className={notificationsStyles.closeContainer}
        onClick={() => {
          client.writeQuery({
            query: GET_NOTIFICATIONS,
            data: {
              notifications: [],
            },
          });
        }}
      >
        <Icon sm type="icon-close" className={notificationsStyles.close} />
      </div>
      <div>
        {notificationsData.notifications.map((notification, i) => {
          const type = typeof notification.type !== 'undefined' ? notification.type : 'error';

          return (
            <div key={notification.key || i} className={cn(notificationsStyles.item, [notificationsStyles[type]])}>
              <div>{notification.message}</div>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default Notifications;
