// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { useLottie, useLottieInteractivity } from 'lottie-react';

const useLottieAnimation = src => {
  const [data, setData] = useState();

  useEffect(() => {
    if (src) {
      // eslint-disable-next-line
      fetch(src)
        .then(response => {
          return response.json();
        })
        .then(data => {
          setData(data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [src]);

  const animationObject = useLottie({
    animationData: data,
    autoplay: false,
    initialSegment: [0, 50],
    onDOMLoaded: () => {
      const container = animationObject.animationContainerRef.current.getBoundingClientRect();
      // eslint-disable-next-line
      if (window.innerHeight / 2 >= container.top) {
        animationObject.play();
      }
    },
  });

  const interactiveObject = useLottieInteractivity({
    lottieObj: animationObject,
    mode: 'scroll',
    actions: [
      {
        visibility: [0.3, 0.6],
        type: 'seek',
        frames: [0, 50],
      },
    ],
  });

  return data ? interactiveObject : undefined;
};

export default useLottieAnimation;
