export const absoluteUrl = ctx => {
  const { req } = ctx;
  let protocol = 'https:';
  // eslint-disable-next-line
  const host = req ? req.headers['x-forwarded-host'] || req.headers.host : window.location.host;

  if (host.includes('localhost')) {
    protocol = 'http:';
  }

  let path;
  if (req) {
    path = ctx.req.url;
  } else {
    path = ctx.asPath;
  }

  const origin = `${protocol}//${host}`;

  return {
    protocol,
    host,
    origin,
    absoluteUrl: `${origin}${path}`,
    relativeUrl: `${path}`,
  };
};

export const twitterUrl = 'https://twitter.com/SweetheartzNFT';
