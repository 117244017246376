import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import Container from '../grid/container';
import Row from '../grid/row';
import Col from '../grid/col';
import Icon from '../icon/icon';
import Button from '../button/button';
import styles from './layout1.module.scss';
import Footer from './footer';
import baseStyles from '../../styles/base.module.scss';
import Logo from '../logo/logo';
import Status from './status';
import { twitterUrl } from '../../utils/urlUtils';

const Layout1 = ({
  children,
  lightBlue,
  pastelYellow,
  className,
  footerClassName,
  headerClassName,
  contentClassName,
  contentContainerClassName,
  contentFull,
  noContentPaddings,
  overflowHidden,
}) => {
  return (
    <div
      className={cn(styles.root, className, {
        [styles.lightBlue]: lightBlue,
        [styles.pastelYellow]: pastelYellow,
        [styles.overflowHidden]: overflowHidden,
      })}
    >
      <div className={cn(styles.header, headerClassName)}>
        <Container>
          <Row span={12}>
            <Col span={10} startCol={2} spanMd={12} startColMd={1} className={styles.container}>
              <div className={styles.logo}>
                <Logo />
              </div>
              <Button className={styles.iconLink} href={twitterUrl} link target="_blank">
                <Icon lg type="icon-social-twitter" className={styles.headerIcon} />
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className={cn(styles.content, contentClassName, {
          [styles.noPaddings]: noContentPaddings,
        })}
      >
        <Container
          full={contentFull}
          className={cn(contentContainerClassName, {
            [styles.noPaddings]: noContentPaddings,
          })}
        >
          <Row span={12}>
            <Col span={12}>{children}</Col>
          </Row>
        </Container>
      </div>
      <Status />
      <Footer className={footerClassName} />
      <style jsx global>{`
        body {
          ${pastelYellow ? `background: ${baseStyles.pastelYellow};` : ''};
          ${lightBlue ? `background: ${baseStyles.lightBlue};` : ''};
        }
      `}</style>
      <div id="particles" />
    </div>
  );
};

Layout1.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  contentContainerClassName: PropTypes.string,
  contentFull: PropTypes.bool,
  footerClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  lightBlue: PropTypes.bool,
  noContentPaddings: PropTypes.bool,
  overflowHidden: PropTypes.bool,
  pastelYellow: PropTypes.bool,
};

Layout1.defaultProps = {
  children: undefined,
  className: undefined,
  contentClassName: undefined,
  contentContainerClassName: undefined,
  contentFull: false,
  footerClassName: undefined,
  headerClassName: undefined,
  lightBlue: false,
  noContentPaddings: false,
  overflowHidden: false,
  pastelYellow: false,
};

export default React.memo(Layout1);
