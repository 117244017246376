import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import modalStyles from './modal.module.scss';
import Popup from './popup';
import Icon from '../icon/icon';

const Modal = ({ title, children, className, visible, showMode, onClose, mobileCentered, desktopCentered }) => {
  return (
    <Popup
      className={cn(modalStyles.animate, [modalStyles[showMode.toLocaleLowerCase()]], {
        [modalStyles.hidden]: !visible,
        [modalStyles.mobileCentered]: mobileCentered,
      })}
    >
      <div
        className={cn(modalStyles.root, className, {
          [modalStyles.desktopCentered]: desktopCentered,
        })}
      >
        <div className={modalStyles.content}>
          <div className={modalStyles.heading}>
            <div className={modalStyles.title}>{title}</div>
            <div className={modalStyles.actionRow}>
              <Icon md type="icon-close" onClick={onClose} className={modalStyles.icon} />
            </div>
          </div>
          <div className={modalStyles.container}>{children}</div>
        </div>
      </div>
    </Popup>
  );
};

export default Modal;

Modal.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  visible: PropTypes.bool,
  mobileCentered: PropTypes.bool,
  desktopCentered: PropTypes.bool,
  onClose: PropTypes.func,
  showMode: PropTypes.oneOf(['Center', 'Bottom', 'Full']),
};

Modal.defaultProps = {
  title: undefined,
  className: '',
  visible: false,
  onClose: () => {},
  showMode: 'Center',
  mobileCentered: false,
  desktopCentered: true,
};
