import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import Container from '../grid/container';
import Row from '../grid/row';
import Col from '../grid/col';
import styles from './footer.module.scss';
import Logo from '../logo/logo';
import Icon from '../icon/icon';
import Button from '../button/button';
import { twitterUrl } from '../../utils/urlUtils';

const Footer = ({ className, noPaddings }) => {
  const intl = useIntl();

  return (
    <div className={cn(styles.root, className)}>
      <Container full noPaddings={noPaddings}>
        <Row span={12}>
          <Col span={12} className={styles.container}>
            <div className={styles.content}>
              <Logo className={styles.logo} />
              <div className={styles.copyrightContainer}>
                <div className={styles.twitter}>
                  <Button href={twitterUrl} link target="_blank" className={styles.twitterCta}>
                    <Icon lg type="icon-social-twitter" className={styles.icon} />
                    {intl.formatMessage({ id: 'footer.twitter' })}
                  </Button>
                </div>
                <div className={styles.copyright}>
                  {intl.formatMessage(
                    { id: 'footer.copyright' },
                    {
                      year: new Date().getFullYear(),
                    }
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  noPaddings: PropTypes.bool,
};

Footer.defaultProps = {
  className: undefined,
  noPaddings: false,
};

export default React.memo(Footer);
