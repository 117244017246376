import React from 'react';
import Head from 'next/head';
import { useIntl } from 'react-intl';
import Layout1 from '../layout/layout1';
import Col from '../grid/col';
import Row from '../grid/row';
import Button from '../button/button';
import Image from '../media/image';
import { useTitle } from '../../hooks';
import errorStyles from './404.module.scss';

const Page404 = () => {
  const intl = useIntl();
  const { getTitle } = useTitle();

  return (
    <Layout1 pastelYellow>
      <Head>
        <title>{getTitle(intl.formatMessage({ id: 'page.404.title' }))}</title>
      </Head>
      <Row span={12}>
        <Col span={10} startCol={2} spanSm={4} startColSm={1}>
          <div className={errorStyles.root}>
            <div className={errorStyles.heart}>
              <Image placeholderLg objectFit="contain" loadFromStorage src="/common/hearts/h_error.png" />
            </div>
            <h2>{intl.formatMessage({ id: '404.header' })}</h2>
            <div className={errorStyles.info}>{intl.formatMessage({ id: '404.info' })}</div>
            <div className={errorStyles.actions}>
              <Button
                onClick={() => {
                  // eslint-disable-next-line
                  location.reload();
                }}
                md
              >
                {intl.formatMessage({ id: 'error.common.refresh' })}
              </Button>
              <Button mnL md link href="mailto:support@newzip.com" target="_blank" className={errorStyles.ctaSupport}>
                {intl.formatMessage({ id: 'error.common.support' })}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Layout1>
  );
};

export default Page404;
