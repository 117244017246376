import { gql } from '@apollo/client';
import { Me, Auth } from '../fragments';

export const GET_ME = gql`
  query GetMe {
    getMe {
      ...Me
    }
  }
  ${Me}
`;

export const GET_USER_BY_SLUG = gql`
  query GetUserBySlug($slug: String!) {
    getUserBySlug(slug: $slug) {
      id
      name
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp($credentials: SignUpInput!) {
    signUp(credentials: $credentials) {
      ...Auth
    }
  }
  ${Auth}
`;

export const SIGN_IN = gql`
  mutation SignIn($credentials: SignInInput!) {
    signIn(credentials: $credentials) {
      ...Auth
    }
  }
  ${Auth}
`;

export const EDIT_PASSWORD = gql`
  mutation EditPassword($currentPassword: String!, $newPassword: String!, $reNewPassword: String!) {
    editMyPassword(currentPassword: $currentPassword, newPassword: $newPassword, reNewPassword: $reNewPassword) {
      token
      user {
        id
        slug
        passwordUpdatedAt
      }
    }
  }
`;

export const EDIT_PASSWORD_BY_ID = gql`
  mutation EditPassword($userId: ID!, $newPassword: String!, $reNewPassword: String!) {
    editPasswordById(userId: $userId, newPassword: $newPassword, reNewPassword: $reNewPassword) {
      ...Me
    }
  }
  ${Me}
`;

export const EDIT_CONSUMER_PROFILE = gql`
  mutation EditConsumerProfile($userId: ID!, $profile: ConsumerProfileInput!) {
    editConsumerProfile(userId: $userId, profile: $profile) {
      ...Me
    }
  }
  ${Me}
`;
