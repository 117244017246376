import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import logoStyles from './logo.module.scss';
import Image from '../media/image';
import { ConfigContext } from '../../contexts/ConfigContext';

const Logo = ({ src, className }) => {
  const { routeResolver } = useContext(ConfigContext);

  let logo = '/common/logo/logo-sweetheartz.svg';

  if (src) logo = src;

  return (
    <div className={cn(logoStyles.root, className)}>
      <Link href={routeResolver.buildRouteFor(routeResolver.routes.index)}>
        <a>
          <Image objectFit="contain" sizes="128px" priority src={logo} title="NewZip" loadFromStorage />
        </a>
      </Link>
    </div>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
};

Logo.defaultProps = {
  className: undefined,
  src: undefined,
};

export default Logo;
