import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
  query GetNotifications {
    notifications @client
  }
`;

export const GET_RESOLUTION = gql`
  query GetResolution {
    isSm @client
  }
`;

export const GET_ERROR_CODE = gql`
  query GetErrorCode {
    errorCode @client
  }
`;
