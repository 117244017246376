import { useState, useEffect } from 'react';

const useValidation = () => {
  const [isValid, setIsValid] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState([]);
  const [init, setInit] = useState(false);
  // eslint-disable-next-line
  let [validationItems, setValidationItems] = useState({});
  const _invalidItems = Object.keys(validationItems)
    .filter(key => !validationItems[key].isValid)
    .map(key => validationItems[key].errors.join('_'))
    .join('.');

  const validateItemByObject = (item, value, args) => {
    const errors = [];
    let isValid = true;

    item.rules.forEach(rule => {
      const params = (args && args[rule.key]) || [];
      const ruleResult = rule.test(value, ...params);
      if (!ruleResult.isValid) {
        isValid = false;
        errors.push(ruleResult.message);
      }
    });

    return {
      errors,
      isValid,
    };
  };

  const validateForm = validateSilently => {
    let validationItemsCopy = {
      ...validationItems,
    };

    Object.keys(validationItemsCopy).forEach(key => {
      validationItemsCopy = {
        ...validationItemsCopy,
        [key]: {
          ...validationItemsCopy[key],
          pristine: validationItemsCopy[key].pristine ? !!validateSilently : validationItemsCopy[key].pristine,
        },
      };
    });

    const isFormValid = !Object.keys(validationItemsCopy).find(key => !validationItemsCopy[key].isValid);
    const formErrors = Object.keys(validationItemsCopy)
      .filter(key => !validationItemsCopy[key].isValid)
      .map(key => ({ key, messages: validationItemsCopy[key].errors }))
      .flat();

    setValidationItems(validationItemsCopy);
    setSubmitted(!submitted ? !validateSilently : submitted);
    setIsValid(isFormValid);
    setErrors(formErrors.reverse());

    return { isFormValid, formErrors };
  };

  const validateItemByKey = (key, value, args) => {
    const item = validationItems[key];
    if (item) {
      const { errors, isValid } = validateItemByObject(item, value, args);
      setValidationItems({
        ...validationItems,
        [key]: {
          ...validationItems[key],
          errors,
          isValid,
          pristine: item.pristine ? value === item.initial : item.pristine,
        },
      });
    }
  };

  const mapItemToInputWrapper = ({ errors, isValid, pristine }, { info, success } = {}) => {
    const showError = !isValid && !pristine && errors.length > 0;
    const [errorMessage] = errors;
    const showInfo = info;
    const showSuccess = !showError && success && !pristine;

    return {
      // eslint-disable-next-line
      helperText: showError ? errorMessage : showSuccess ? success : showInfo ? info : undefined,
      // eslint-disable-next-line
      status: showError ? 'error' : showSuccess ? 'success' : showInfo ? 'info' : undefined,
    };
  };

  useEffect(() => {
    setInit(true);
  }, []);

  useEffect(() => {
    validateForm(true);
  }, [_invalidItems]);

  return {
    submit: handler => {
      return {
        onKeyDown: e => {
          if (e.key.toLowerCase() !== 'enter' || e.keyCode !== 13) {
            return;
          }

          e.target.blur();

          const eventName = 'submit';
          let event;
          // eslint-disable-next-line
          if (document.createEvent) {
            // eslint-disable-next-line
            event = document.createEvent('HTMLEvents');
            event.initEvent(eventName, true, true);
            event.eventName = eventName;
            const t = e.currentTarget;
            setTimeout(() => {
              t.dispatchEvent(event);
            });
          } else {
            // eslint-disable-next-line
            event = document.createEventObject();
            event.eventName = eventName;
            event.eventType = eventName;
            const t = e.currentTarget;
            setTimeout(() => {
              t.fireEvent(`on${event.eventType}`, event);
            });
          }
        },
        onSubmit: e => {
          if (e) {
            e.preventDefault();
            e.stopPropagation();
          }

          const { isFormValid, formErrors } = validateForm();
          setTimeout(() => {
            handler({ isValid: isFormValid, errors: formErrors, submitted: true });
          });
        },
      };
    },
    register: (key, initialValue, rules, { disabled = false, ...options } = {}) => {
      if (disabled || !init) return {};

      if (!validationItems[key]) {
        const item = {
          initial: initialValue,
          isValid: true,
          pristine: true,
          errors: [],
          rules,
        };
        const { errors, isValid } = validateItemByObject(item, initialValue);

        const validatedItem = {
          ...item,
          errors,
          isValid,
        };

        setValidationItems({
          ...validationItems,
          [key]: {
            ...validatedItem,
          },
        });

        return mapItemToInputWrapper(validatedItem, options);
      }

      const item = validationItems[key];
      return mapItemToInputWrapper(item, options);
    },
    remove: (key, { startsWith = false } = {}) => {
      if (startsWith) {
        Object.keys(validationItems).forEach(vKey => {
          if (vKey.startsWith(key)) {
            delete validationItems[vKey];
          }
        });

        setValidationItems({ ...validationItems });
      } else if (validationItems[key]) {
        delete validationItems[key];
        setValidationItems({ ...validationItems });
      }
    },
    validate: (key, value, args) => {
      validateItemByKey(key, value, args);
    },
    removeAll: () => {
      setValidationItems({});
    },
    isValid,
    errors,
    submitted,
  };
};

export default useValidation;
