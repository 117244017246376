// eslint-disable-next-line
import React, { useEffect, useRef, useState, useContext } from 'react';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import { toGatewayURL } from 'nft.storage';
import Sweetheartz from '../../crypto/abis/Sweetheartz.json';
import { walletconnect } from './web3Providers';
import { Context as Web3Context } from '../contexts/Web3Context';

let web3;
let contract;
if (typeof window === 'undefined') {
  web3 = new Web3.providers.HttpProvider(process.env.NEXT_PUBLIC_PROVIDER_OR_URL);
}

let web3Modal;
if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: process.env.NEXT_PUBLIC_NETWORK_NAME,
    cacheProvider: true,
    providerOptions: {
      walletconnect,
    },
  });
}

const useWeb3 = () => {
  const { setTotalSupply, setSupplyUsed, setAuthUserCanMintFree, setFreeMintLimitReached, setFreeMintTokensLeft } =
    useContext(Web3Context);
  const web3Ref = useRef(web3);
  const selectedWalletProviderRef = useRef();
  const hashIntervalRef = useRef();
  const tokenOwnerAddressIntervalRef = useRef();

  const [loading, setLoading] = useState(true);
  const [contractInstance, setContractInstance] = useState(contract);
  const [contractOwnerAddress, setContractOwnerAddress] = useState(null);
  const [authUserAddress, setAuthUserAddress] = useState(null);
  const [connected, setConnected] = useState(false);
  const [wrongNetwork, setWrongNetwork] = useState(false);

  const loadContract = async networkId => {
    setWrongNetwork(networkId !== parseInt(process.env.NEXT_PUBLIC_NETWORK_ID, 10));

    let provider = new Web3.providers.HttpProvider(process.env.NEXT_PUBLIC_PROVIDER_OR_URL);
    if (networkId === parseInt(process.env.NEXT_PUBLIC_NETWORK_ID, 10) && web3Modal.cachedProvider) {
      provider = await web3Modal.connect();
    }

    const contractWeb3 = new Web3(provider);
    const ethContract = new contractWeb3.eth.Contract(Sweetheartz.abi, process.env.NEXT_PUBLIC_CONTRACT_ADDRESS);
    const owner = await ethContract.methods.owner().call();

    setContractInstance(ethContract);
    setContractOwnerAddress(owner.toLowerCase());
    if (owner) {
      setContractOwnerAddress(owner.toLowerCase());
    }

    setLoading(false);
  };

  const setAccount = async account => {
    if (account) {
      setAuthUserAddress(account.toLowerCase());
      setConnected(true);
    } else {
      setAuthUserAddress(null);
      setConnected(false);
    }
  };

  const onCloseHandler = async () => {
    setAccount(null);
    await web3Modal.clearCachedProvider();
  };

  const accountsChangedHandler = async accounts => {
    const [account] = accounts;
    setAccount(account);
    if (!account) {
      await web3Modal.clearCachedProvider();
    }
  };

  const networkChangedHandler = async networkId => {
    await loadContract(parseInt(networkId, 10));
  };

  const disconnectHandler = async () => {
    setAccount(null);
    await web3Modal.clearCachedProvider();
  };

  const subscribeToProviderEvents = () => {
    if (!selectedWalletProviderRef?.current?.on) {
      return;
    }

    selectedWalletProviderRef.current.removeListener('close', onCloseHandler);
    selectedWalletProviderRef.current.removeListener('accountsChanged', accountsChangedHandler);
    selectedWalletProviderRef.current.removeListener('networkChanged', networkChangedHandler);
    selectedWalletProviderRef.current.removeListener('disconnect', disconnectHandler);

    selectedWalletProviderRef.current.on('close', onCloseHandler);
    selectedWalletProviderRef.current.on('accountsChanged', accountsChangedHandler);
    selectedWalletProviderRef.current.on('networkChanged', networkChangedHandler);
    selectedWalletProviderRef.current.on('disconnect', disconnectHandler);
  };

  const tryConnect = async provider => {
    web3Ref.current = new Web3(provider);
    selectedWalletProviderRef.current = provider;
    const networkId = await web3Ref.current.eth.net.getId();
    await loadContract(networkId);
    subscribeToProviderEvents();
    const [account] = await web3Ref.current.eth.getAccounts();
    setAccount(account);
  };

  const showWeb3Modal = async () => {
    try {
      const provider = await web3Modal.connect();
      await tryConnect(provider);
    } catch (err) {
      console.log('showWeb3Modal err', err);
    }
  };

  const calculateTotalSupply = async () => {
    const supplyUsed = parseInt(await contractInstance.methods.totalSupply().call(), 10);
    const supplyMax = parseInt(await contractInstance.methods.MAX_SUPPLY().call(), 10);
    setTotalSupply(supplyMax - supplyUsed);
    setSupplyUsed(supplyUsed);
  };

  const calculateFreeMintLimitReached = async () => {
    const freeSupplyUsed = parseInt(await contractInstance.methods.totalFreeSupply().call(), 10);
    const freeSupplyMax = parseInt(await contractInstance.methods.MAX_FREE_TOKENS().call(), 10);
    setFreeMintLimitReached(freeSupplyUsed >= freeSupplyMax);
    setFreeMintTokensLeft(freeSupplyMax - freeSupplyUsed);
  };

  const calculateAuthUserCanMintFree = async () => {
    const canMintFree = await contractInstance.methods.canMintFreeToken().call({ from: authUserAddress });
    setAuthUserCanMintFree(canMintFree);
  };

  useEffect(() => {
    if (contractInstance) {
      calculateTotalSupply();
      calculateFreeMintLimitReached();
    }
  }, [contractInstance]);

  useEffect(async () => {
    if (authUserAddress && contractInstance) {
      calculateAuthUserCanMintFree();
    }
  }, [authUserAddress, contractInstance]);

  useEffect(() => {
    const init = async () => {
      if (!web3) {
        try {
          let provider;
          if (web3Modal.cachedProvider) {
            provider = await web3Modal.connect();
          } else {
            provider = new Web3.providers.HttpProvider(process.env.NEXT_PUBLIC_PROVIDER_OR_URL);
          }

          await tryConnect(provider);
        } catch (err) {
          if (web3Modal) {
            await web3Modal.clearCachedProvider();
          }
          console.log('Init error', err);
        }
      }
    };

    init();

    return () => {
      if (hashIntervalRef.current) {
        clearInterval(hashIntervalRef.current);
      }

      if (tokenOwnerAddressIntervalRef.current) {
        clearInterval(tokenOwnerAddressIntervalRef.current);
      }
    };
  }, []);

  const getENSAddress = async ensName => {
    return new Promise(resolve => {
      web3Ref.current.eth.ens.getAddress(ensName, (err, address) => {
        if (err && !address) {
          return resolve(null);
        }

        resolve(address);
      });
    });
  };

  const validateAddress = async address => {
    const isAddress = web3Ref.current.utils.isAddress(address);
    if (!isAddress) {
      return getENSAddress(address);
    }
    return address;
  };

  const mintByOwner = async (address, callback) => {
    address = await validateAddress(address);
    if (address && authUserAddress) {
      return contractInstance.methods
        .mint(address)
        .send({ from: authUserAddress }, (_, trxHash) => {
          if (callback && trxHash) {
            callback(trxHash);
          }
        })
        .once('receipt', () => {
          calculateTotalSupply();
          calculateFreeMintLimitReached();
          calculateAuthUserCanMintFree();
        });
    }
  };

  const mintByGuest = async (address, callback) => {
    address = await validateAddress(address);
    if (address && authUserAddress) {
      const price = await contractInstance.methods.getMintPrice().call();
      return contractInstance.methods
        .mintTo(address)
        .send({ from: authUserAddress, value: price }, (_, trxHash) => {
          if (callback && trxHash) {
            callback(trxHash);
          }
        })
        .once('receipt', () => {
          calculateTotalSupply();
          calculateFreeMintLimitReached();
          calculateAuthUserCanMintFree();
        });
    }
  };

  const mint = (address, callback) => {
    return authUserAddress === contractOwnerAddress ? mintByOwner(address, callback) : mintByGuest(address, callback);
  };

  const getTokenIdByTxnHash = async hash => {
    let retries = 0;
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      const checkTransactionReceipt = async () => {
        clearInterval(hashIntervalRef.current);
        const trxExist = await web3Ref.current.eth.getTransaction(hash);
        if (trxExist) {
          const rcpExist = await web3Ref.current.eth.getTransactionReceipt(hash);
          if (rcpExist) {
            const tokenId = await contractInstance.methods.getLatestOwnerTokenId().call({ from: authUserAddress });
            if (parseInt(tokenId, 10) === 0) {
              // getLatestOwnerTokenId returns 0 also in scenario when user does not exist in contract mapping.
              // That is why we need additional logic in order to distinguish it.
              const supplyUsed = parseInt(await contractInstance.methods.totalSupply().call(), 10);
              if (supplyUsed > 0) {
                hashIntervalRef.current = setInterval(checkTransactionReceipt, 5000);
              } else {
                resolve(tokenId);
              }
            } else {
              resolve(tokenId);
            }
          } else {
            hashIntervalRef.current = setInterval(checkTransactionReceipt, 5000);
          }
        } else if (retries < 3) {
          retries += 1;
          hashIntervalRef.current = setInterval(checkTransactionReceipt, 3000);
        } else {
          clearInterval(hashIntervalRef.current);
          reject();
        }
      };

      await checkTransactionReceipt();
    });
  };

  const getTokenOwnerAddress = async tokenId => {
    return new Promise((resolve, reject) => {
      let retries = 0;

      const getAddressFn = async () => {
        try {
          clearInterval(tokenOwnerAddressIntervalRef.current);
          const address = await contractInstance.methods.ownerOf(tokenId).call();
          resolve(address);
        } catch (err) {
          if (retries < 5) {
            retries += 1;
            tokenOwnerAddressIntervalRef.current = setInterval(getAddressFn, 3000);
          } else {
            reject();
          }
        }
      };

      getAddressFn();
    });
  };

  const getTokenMetadata = async tokenId => {
    const url = await contractInstance.methods.tokenURI(tokenId).call();
    const { href } = toGatewayURL(url);

    let intervalCount = 0;
    let completed = false;
    // eslint-disable-next-line
    const getDataFn = async () => fetch(href).then(res => (res.status == 404 || res.status == 200 ? res.json() : null));
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      let data;
      while (!completed) {
        // eslint-disable-next-line
        data = await getDataFn().catch(() => {
          completed = true;
          reject();
        });

        if (data || completed) {
          completed = true;
        } else {
          intervalCount += 1;
          completed = intervalCount === 5;
        }
      }
      resolve(data);
    });
  };

  const disconnect = async () => {
    await web3Modal.clearCachedProvider();
    // eslint-disable-next-line
    window.location.reload();
  };

  return {
    contractInstance,
    showWeb3Modal,
    connected,
    wrongNetwork,
    mint,
    authUserAddress,
    contractOwnerAddress,
    getTokenIdByTxnHash,
    getTokenMetadata,
    getTokenOwnerAddress,
    loading,
    validateAddress,
    disconnect,
  };
};

export default useWeb3;
