import React, { useEffect } from 'react';
import { useWeb3, useAnalytics } from './hooks';

const Identify = () => {
  const { authUserAddress } = useWeb3();
  const { identify } = useAnalytics();

  useEffect(() => {
    identify(authUserAddress);
  }, [authUserAddress]);
  return <div />;
};

export default Identify;
