import PropTypes from 'prop-types';
import React, { useEffect, useContext, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useQuery, useApolloClient } from '@apollo/client';
import { init } from '../utils/sentry';
import { useAnalytics, useAuth, useRole } from '../app/hooks';
import { withApollo } from '../app/gql/apollo/apollo';
import '../app/styles/global.scss';
import { ConfigContext } from '../app/contexts/ConfigContext';
import ErrorContainer from '../app/containers/error';
import { renderingType } from '../utils/app';
import { GET_ERROR_CODE } from '../app/gql/query';

init();

// eslint-disable-next-line
const App = function App({ Component, pageProps }) {
  const client = useApolloClient();
  const { page, identify, group } = useAnalytics();
  const { user } = useContext(ConfigContext);
  const [canAccess, setCanAccess] = useState(true);
  const permissions = Component.permissions || [];
  const roles = Component.roles || [];
  const { pageName } = Component;
  const { can: pageAccess } = useAuth(permissions, user.permissions);
  const roleAccess = useRole(roles).is(user);
  const router = useRouter();
  const pageNameRef = useRef();
  pageNameRef.current = pageName;

  const { data } = useQuery(GET_ERROR_CODE);
  const errorStatusCode = (data && data.errorCode) || false;

  useEffect(() => {
    if (user.id) {
      identify();
      group();
    }
  }, [user.id, user.updated]);

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      if (errorStatusCode) {
        client.writeQuery({
          query: GET_ERROR_CODE,
          data: {
            errorCode: null,
          },
        });
      }
      if (!shallow) {
        page(pageNameRef.current);
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, pageName]);

  useEffect(() => {
    page(pageName);
  }, []);

  const validateAccess = can => {
    setCanAccess(can);
  };

  if (errorStatusCode) return <ErrorContainer statusCode={errorStatusCode} />;

  return (pageProps.renderingType === renderingType.ssr && pageAccess && roleAccess && canAccess) ||
    pageProps.renderingType !== renderingType.ssr ? (
    <Component {...pageProps} validateAccess={validateAccess} />
  ) : (
    <ErrorContainer statusCode={404} />
  );
};

export default withApollo()(App);

App.propTypes = {
  Component: PropTypes.any.isRequired,
  pageProps: PropTypes.any.isRequired,
};
