/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useQuery } from '@apollo/client';
import { GET_RESOLUTION } from '../gql/query';

const useResolution = () => {
  const { data: resolutionData } = useQuery(GET_RESOLUTION);
  const [items, setItems] = useState({
    isSm: false || (resolutionData && resolutionData.isSm),
    isMd: false,
    isLg: false,
    isXl: false,
  });

  const isClient = typeof window !== 'undefined';

  const resizeHandler = () => {
    isClient &&
      setItems({
        isSm: window.innerWidth <= 767,
        isMd: window.innerWidth <= 1023,
        isLg: window.innerWidth <= 1439,
        isXl: window.innerWidth > 1440,
      });
  };
  const onResizeDebounced = useDebouncedCallback(resizeHandler, 100);

  useEffect(() => {
    if (isClient) {
      window.addEventListener('resize', onResizeDebounced);
      resizeHandler();
    }

    return () => window.removeEventListener('resize', onResizeDebounced);
  }, [isClient]);

  return {
    ...items,
  };
};

export default useResolution;
