// eslint-disable-next-line
import React from 'react';

const useUrl = (url = '') => {
  return {
    absoluteUrl: `${process.env.NEXT_PUBLIC_CONSUMER_WEB_HOST}${url}`,
    relativeUrl: url,
  };
};

export default useUrl;
