import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import enTranslations from './i18n/en';
import Notifications from './components/notifications/notifications';
import GeneralModals from './components/popup/generalModals';
import RouteResolver from './utils/routeResolver';
import { ConfigContext } from './contexts/ConfigContext';
import { Provider as Web3Provider } from './contexts/Web3Context';
import Identify from './indentify';

const config = {
  locale: 'en',
  user: {
    id: null,
    permissions: [],
    role: [],
    updated: null,
    loading: true,
  },
  routeResolver: new RouteResolver(),
};

const ProviderFactory = ({ children }) => {
  const providers = {
    Web3Provider,
  };

  return Object.keys(providers).reduce((Component, key) => {
    const ProviderComponent = providers[key];
    return <ProviderComponent>{Component}</ProviderComponent>;
  }, children);
};

const App = ({ children }) => {
  const [contextConfig] = useState({ ...config });

  contextConfig.user = {
    ...contextConfig.user,
  };

  return (
    <ConfigContext.Provider value={contextConfig}>
      <IntlProvider locale={contextConfig.locale} messages={enTranslations}>
        <ProviderFactory>
          <Identify />
          <Notifications />
          <GeneralModals />
          {children}
        </ProviderFactory>
      </IntlProvider>
    </ConfigContext.Provider>
  );
};

export default App;

App.propTypes = {
  children: PropTypes.any.isRequired,
};
