import React, { useState } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import buttonStyles from './button.module.scss';

const Button = ({
  href,
  to,
  target,
  className,
  secondary,
  children,
  onClick,
  onMouseDown,
  link,
  lg,
  sm,
  md,
  block,
  loading,
  disabled,
  autoHeight,
  noPaddings,
  icon,
  iconLeft,
  type,
  mnL,
  mnR,
  mnB,
  mnT,
  round,
}) => {
  const [hasPendingPromise, setHasPendingPromise] = useState(false);

  const isLoading = () => {
    return hasPendingPromise || loading;
  };

  const isFunction = functionToCheck => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  };

  const onClickHandler = event => {
    if (disabled || isLoading()) {
      return;
    }

    if (!onClick) {
      return;
    }

    const clickResult = onClick(event);
    if (clickResult && isFunction(clickResult.finally)) {
      setHasPendingPromise(true);
      clickResult.finally(() => setHasPendingPromise(false));
    }
  };

  const classNames = cn(buttonStyles.root, className, {
    [buttonStyles.secondary]: secondary,
    [buttonStyles.disabled]: disabled,
    [buttonStyles.autoHeight]: autoHeight,
    [buttonStyles.noPaddings]: noPaddings,
    [buttonStyles.link]: link,
    [buttonStyles.large]: lg,
    [buttonStyles.small]: sm,
    [buttonStyles.medium]: md,
    [buttonStyles.block]: block,
    [buttonStyles.loading]: isLoading(),
    [buttonStyles.iconRight]: !iconLeft,
    [buttonStyles.marginTop]: mnT,
    [buttonStyles.marginRight]: mnR,
    [buttonStyles.marginBottom]: mnB,
    [buttonStyles.marginLeft]: mnL,
    [buttonStyles.iconWithoutText]: icon && !children,
    [buttonStyles.round]: round,
  });

  const renderIcon = () => {
    return icon && <span className={buttonStyles.icon}>{icon}</span>;
  };

  const renderContent = () => (
    <>
      {iconLeft && renderIcon()}
      {children}
      {!iconLeft && renderIcon()}
    </>
  );

  if (href) {
    return (
      <a
        target={target}
        rel="noopener noreferrer"
        href={!disabled && href}
        className={classNames}
        onClick={onClickHandler}
      >
        {renderContent()}
      </a>
    );
  }

  if (to) {
    return (
      <Link href={to}>
        <a className={classNames} onClick={onClickHandler}>
          {renderContent()}
        </a>
      </Link>
    );
  }

  return (
    // eslint-disable-next-line
    <button type={type} className={classNames} onClick={onClickHandler} onMouseDown={onMouseDown}>
      {renderContent()}
    </button>
  );
};

Button.propTypes = {
  autoHeight: PropTypes.bool,
  block: PropTypes.bool,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.node,
  iconLeft: PropTypes.bool,
  lg: PropTypes.bool,
  link: PropTypes.bool,
  loading: PropTypes.bool,
  md: PropTypes.bool,
  mnB: PropTypes.bool,
  mnL: PropTypes.bool,
  mnR: PropTypes.bool,
  mnT: PropTypes.bool,
  noPaddings: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  round: PropTypes.bool,
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  target: PropTypes.string,
  to: PropTypes.any,
  type: PropTypes.string,
};

Button.defaultProps = {
  autoHeight: false,
  block: false,
  className: undefined,
  disabled: false,
  href: undefined,
  icon: undefined,
  iconLeft: true,
  lg: false,
  link: false,
  loading: false,
  md: false,
  mnB: false,
  mnL: false,
  mnR: false,
  mnT: false,
  noPaddings: false,
  onClick: () => {},
  onMouseDown: () => {},
  round: false,
  secondary: false,
  sm: false,
  target: undefined,
  to: undefined,
  type: 'button',
};

export default Button;
