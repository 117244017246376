import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import baseStyles from '../../styles/base.module.scss';

const Row = ({ span, spanLg, spanMd, spanSm, spanXs, className, children }) => {
  return (
    <div
      className={cn(className, {
        [baseStyles[`grid_${span}`]]: span,
        [baseStyles[`grid_lg_${spanLg}`]]: spanLg,
        [baseStyles[`grid_md_${spanMd}`]]: spanMd,
        [baseStyles[`grid_sm_${spanSm}`]]: spanSm,
        [baseStyles[`grid_xs_${spanXs}`]]: spanXs,
      })}
    >
      {children}
    </div>
  );
};

Row.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  span: PropTypes.number,
  spanLg: PropTypes.number,
  spanMd: PropTypes.number,
  spanSm: PropTypes.number,
  spanXs: PropTypes.number,
};

Row.defaultProps = {
  className: '',
  span: undefined,
  spanLg: undefined,
  spanMd: undefined,
  spanSm: undefined,
  spanXs: undefined,
};

export default React.memo(Row);
