import { gql } from '@apollo/client';

export const Remove = gql`
  fragment Remove on RemoveType {
    success
    id
  }
`;

export const PageInfo = gql`
  fragment PageInfo on PageInfo {
    previous
    next
    hasPrevious
    hasNext
    total
  }
`;
