// eslint-disable-next-line
import React from 'react';
import { Loader } from '@googlemaps/js-api-loader';

let init = false;

const useGoogleMapsAPI = () => {
  const onLoad = callback => {
    if (!init) {
      const loader = new Loader({
        apiKey: process.env.NEXT_PUBLIC_MAPS_KEY,
        libraries: ['places'],
      });
      loader.load().then(() => {
        init = true;
        // eslint-disable-next-line
        callback(window.google);
      });
      init = true;
    } else {
      // eslint-disable-next-line
      callback(window.google);
    }
  };

  return {
    onLoad,
  };
};

export default useGoogleMapsAPI;
