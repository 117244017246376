import WalletConnect from '@walletconnect/web3-provider';
import WalletLink from 'walletlink';

const infuraId = process.env.NEXT_PUBLIC_INFURA_ID;

export const walletconnect = {
  package: WalletConnect,
  options: {
    infuraId,
    display: {
      name: 'Mobile',
      description: 'Scan qrcode with your mobile wallet',
    },
  },
};
export const walletlink = {
  package: WalletLink,
  options: {
    appName: 'Web3Modal Example App',
    infuraId,
  },
};
