import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import containerStyles from './container.module.scss';

const Container = ({ full, noPaddings, children, className }) => {
  return (
    <div
      className={cn(containerStyles.root, className, {
        [containerStyles.full]: full,
        [containerStyles.noPaddings]: noPaddings,
      })}
    >
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  full: PropTypes.bool,
  noPaddings: PropTypes.bool,
};

Container.defaultProps = {
  className: '',
  full: false,
  noPaddings: false,
};

export default React.memo(Container);
