export const profileInfoMapImageDimensions = {
  width: '640',
  height: '278',
};

export const profileInfoMapImageDimensionsMobile = {
  width: '375',
  height: '220',
};

export const neighborhoodCardMapImageDimensions = {
  width: '200',
  height: '200',
};

export const neighborhoodRecommendationMapImageDimensions = {
  width: '510',
  height: '310',
};

export const storageUrl = () => {
  return `https://storage.googleapis.com/${process.env.NEXT_PUBLIC_GOOGLE_BUCKET}`;
};

export const cdnUrl = () => {
  return `${process.env.NEXT_PUBLIC_IMAGE_PATH}`;
};

export const buildImageStorageUrl = src => {
  return `${storageUrl()}/consumer-web${src}`;
};

export const buildCDNImageUrl = src => {
  return `${cdnUrl()}/consumer-web${src}`;
};

export const neighborhoodStreetViewImageDimensions = {
  width: '670',
  height: '520',
};

export const CITY_IMAGES = {
  austin: {
    imageSrc: '/index/heroAustin.jpg',
  },
  nashville: {
    imageSrc: '/index/heroNashville.jpg',
  },
  miami: {
    imageSrc: '/index/heroMiami.jpg',
  },
};
