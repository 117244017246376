import { compile } from 'path-to-regexp';
import { routesKeys } from '../constants/router';

class RouteResolver {
  constructor() {
    this._routes = [
      {
        id: routesKeys.index,
        path: '/',
      },
      {
        id: routesKeys.details,
        path: '/:id',
      },
    ];

    this._defaultOptions = {
      query: undefined,
    };
  }

  get routes() {
    return routesKeys;
  }

  _resolveOptions(options) {
    return { ...this._defaultOptions, ...options };
  }

  getRouteById(routeId) {
    return this._routes.find(r => r.id === routeId);
  }

  buildRouteFor(routeId, uriParams, options = {}) {
    const { pathname, query } = this._resolveOptions({ ...options });

    if (!uriParams) {
      uriParams = {};
    }

    const route = this._routes.find(r => {
      return r.id === routeId;
    });

    const path = pathname || route.path;
    const toPath = compile(path);

    let uri = toPath(uriParams, { encode: x => x });
    if (uri === '') {
      uri = '/';
    }

    const resultObj = {
      pathname: uri,
    };

    let search;
    if (query) {
      search = '?';

      // eslint-disable-next-line
      for (const p in query) {
        if (search !== '?') {
          search += '&';
        }
        const value = query[p];
        if (value !== '') {
          search += `${p}=${query[p]}`;
        }
      }

      if (search !== '?') {
        resultObj.search = search;
      }
    }

    return `${resultObj.pathname}${resultObj.search ? resultObj.search : ''}`;
  }
}

export default RouteResolver;
