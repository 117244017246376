// eslint-disable-next-line
import React from 'react';
import analyticsEvents from '../utils/analyticEvents';

const useAnalytics = () => {
  const identify = userToIdentify => {
    // eslint-disable-next-line
    if (typeof analytics !== 'undefined') {
      // eslint-disable-next-line
      analytics.identify(userToIdentify);
    }
  };

  const track = (event, props) => {
    // eslint-disable-next-line
    if (typeof analytics !== 'undefined') {
      // eslint-disable-next-line
      analytics.track(event, props);
    }
  };

  const page = name => {
    // eslint-disable-next-line
    if (typeof analytics !== 'undefined') {
      // eslint-disable-next-line
      analytics.page(name);
    }
  };

  const reset = () => {
    // eslint-disable-next-line
    if (typeof analytics !== 'undefined') {
      // eslint-disable-next-line
      analytics.reset();
    }
  };

  return {
    identify,
    track,
    page,
    reset,
    events: analyticsEvents,
  };
};

export default useAnalytics;
