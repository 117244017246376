import { gql } from '@apollo/client';

export const Me = gql`
  fragment Me on User {
    id
    slug
    name
    email
    phone {
      value
      formattedValue
      countryCode
    }
    image {
      url
    }
    role
    updated
    passwordUpdatedAt
    permissions
    profileConsumer {
      city {
        id
        name
        admLvl1 {
          shortName
        }
      }
    }
  }
`;

export const Auth = gql`
  fragment Auth on Auth {
    token
    user {
      id
      slug
      name
      email
      phone {
        value
        formattedValue
        countryCode
      }
      role
    }
  }
`;
