import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import NotificationModal from './notificationModal';
import { ConfigContext } from '../../contexts/ConfigContext';

const prState = {
  success: 'success',
  error: 'error',
};

const GeneralModals = () => {
  const router = useRouter();
  const { user } = useContext(ConfigContext);
  const intl = useIntl();
  const { pr, ...queryRest } = router.query;

  const hideModal = () => {
    router.replace(
      {
        pathname: router.route,
        query: queryRest,
      },
      undefined,
      { shallow: true }
    );
  };

  return (
    <div>
      {pr === prState.success && (
        <NotificationModal
          show
          type="success"
          title={intl.formatMessage({ id: 'generalModals.success.title' })}
          text={intl.formatMessage(
            { id: 'generalModals.success.text' },
            {
              email: <b>{user.email}</b>,
            }
          )}
          ctaText={intl.formatMessage({ id: 'generalModals.success.cta' })}
          onClose={hideModal}
          ctaOnClick={hideModal}
        />
      )}
      {pr === prState.error && (
        <NotificationModal
          show
          type="error"
          title={intl.formatMessage({ id: 'generalModals.error.title' })}
          text={intl.formatMessage({ id: 'generalModals.error.text' })}
          ctaText={intl.formatMessage({ id: 'generalModals.error.cta' })}
          onClose={hideModal}
          ctaOnClick={hideModal}
        />
      )}
    </div>
  );
};

export default GeneralModals;
