import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import MobileDetect from 'mobile-detect';
// eslint-disable-next-line
import { onError } from '@apollo/client/link/error';
// eslint-disable-next-line
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { parseCookies } from 'nookies';
import { typeDefs, resolvers } from './resolvers';
import { GET_RESOLUTION, GET_NOTIFICATIONS, GET_ERROR_CODE } from '../query';
import { errorCode } from '../../utils/errorCodes';

// eslint-disable-next-line
export default function createApolloClient(initialState, ctx) {
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    let notifications = [];

    if (graphQLErrors) {
      if (graphQLErrors.some(g => g.extensions.code === errorCode.NOT_FOUND)) {
        // eslint-disable-next-line
        client.writeQuery({
          query: GET_ERROR_CODE,
          data: { errorCode: 404 },
        });
      } else {
        const { query } = operation;
        const [operationDefinition] = query.definitions;
        if (operationDefinition.operation === 'query') {
          // eslint-disable-next-line
          client.writeQuery({
            query: GET_ERROR_CODE,
            data: { errorCode: 500 },
          });
        }
      }

      notifications = notifications.concat(
        graphQLErrors
          .filter(g => g.extensions.code !== errorCode.NOT_FOUND)
          .map(({ message, path }) => ({
            message,
            key: path,
            type: 'error',
          }))
      );
    }

    if (networkError) {
      notifications.push({
        message: networkError.message,
        key: 'NetworkError',
        type: 'error',
      });
    }

    // eslint-disable-next-line
    client.writeQuery({
      query: GET_NOTIFICATIONS,
      data: {
        notifications,
      },
    });
  });

  const httpLink = createUploadLink({
    uri:
      typeof window !== 'undefined'
        ? process.env.NEXT_PUBLIC_API_ENDPOINT
        : process.env.NEXT_PUBLIC_API_ENDPOINT_SERVER,
    headers: {
      'keep-alive': 'true',
    },
  });

  const authLink = setContext((_, { headers }) => {
    let authorization = '';
    const cookies = parseCookies(ctx);
    const token = cookies.at_eu;
    authorization = token ? `Bearer ${token}` : '';
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization,
      },
    };
  });

  const client = new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache({
      typePolicies: {
        Country: {
          merge: true,
        },
        AdmLvl1: {
          merge: true,
        },
        ProfileNA: {
          merge: true,
        },
        Query: {
          fields: {
            //
          },
        },
      },
    }).restore(initialState),
    typeDefs,
    resolvers,
  });

  if (ctx && ctx.req) {
    const md = new MobileDetect(ctx.req.headers['user-agent']);
    // eslint-disable-next-line
    client.writeQuery({
      query: GET_RESOLUTION,
      data: {
        isSm: !!md.mobile(),
      },
    });
  }

  return client;
}
