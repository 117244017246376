import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import styles from './status.module.scss';
import Button from '../button/button';
import Container from '../grid/container';
import Row from '../grid/row';
import Col from '../grid/col';
import Image from '../media/image';
import { Context as Web3Context } from '../../contexts/Web3Context';
import { ConfigContext } from '../../contexts/ConfigContext';
import { useWeb3, useResolution, useAnalytics } from '../../hooks';

const Footer = ({ className }) => {
  const { track, events } = useAnalytics();
  const intl = useIntl();
  const { isSm } = useResolution();
  const { totalSupply, supplyUsed } = useContext(Web3Context);
  const router = useRouter();
  const { routeResolver } = useContext(ConfigContext);
  const { connected } = useWeb3();
  const heartsOnPage = isSm ? 7 : 6;
  const [heartsToShow, setHeartsToShow] = useState();

  useEffect(() => {
    if (supplyUsed) {
      const res = [];
      // eslint-disable-next-line
      for (let i = supplyUsed - 1; i >= 0 && i >= supplyUsed - heartsOnPage; i--) {
        res.push(i);
      }

      setHeartsToShow(res);
    }
  }, [supplyUsed]);

  const loadMore = () => {
    track(events.loadMoreRecentlyMinted);

    const lastItem = heartsToShow[heartsToShow.length - 1];
    if (lastItem === 0) {
      return;
    }

    const res = [...heartsToShow];
    // eslint-disable-next-line
    for (let i = lastItem - 1; i >= 0 && i >= lastItem - (heartsOnPage + 1); i--) {
      res.push(i);
    }
    setHeartsToShow(res);
  };

  const nextPage = heartsToShow && heartsToShow[heartsToShow.length - 1] > 0;

  return (
    <div className={styles.container}>
      <Container>
        <Row span={12}>
          <Col span={10} startCol={2} spanMd={12} startColMd={1}>
            <div className={cn(styles.root, className)}>
              <div className={styles.title}>{intl.formatMessage({ id: 'status.title' })}</div>
              <div className={styles.list}>
                <>
                  {heartsToShow?.map(tokenId => (
                    <a
                      className={styles.heart}
                      href={routeResolver.buildRouteFor(routeResolver.routes.details, { id: tokenId })}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        objectFit="contain"
                        unoptimized
                        src={`https://${process.env.NEXT_PUBLIC_IPFS_CID}.ipfs.dweb.link/images/${tokenId}.png`}
                      />
                    </a>
                  ))}
                  {nextPage && (
                    <div onClick={loadMore} className={cn(styles.heart, styles.loadMore)}>
                      {intl.formatMessage({ id: 'status.loadMore' })}
                    </div>
                  )}
                </>
              </div>
              <div className={styles.ctaContainer}>
                <Button
                  lg
                  className={styles.cta}
                  onClick={() => {
                    if (router.pathname === '/') {
                      // eslint-disable-next-line
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    } else {
                      router.push(routeResolver.buildRouteFor(routeResolver.routes.index));
                    }
                  }}
                >
                  {intl.formatMessage({ id: `status.${connected ? 'mint' : 'connectToWallet'}` })}
                </Button>
                <Button lg href={process.env.NEXT_PUBLIC_OPENSEA_COLLECTION} target="_blank" secondary>
                  {intl.formatMessage({ id: 'status.viewOpenSea' })}
                </Button>
              </div>
              <div className={styles.available}>
                {intl.formatMessage(
                  { id: 'status.available' },
                  {
                    available: totalSupply || '...',
                  }
                )}
              </div>
              <div className={styles.verified}>
                {intl.formatMessage(
                  { id: 'status.verified' },
                  {
                    link: (
                      <a
                        href={`${process.env.NEXT_PUBLIC_ETHERSCAN}/address/${process.env.NEXT_PUBLIC_CONTRACT_ADDRESS}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {process.env.NEXT_PUBLIC_CONTRACT_ADDRESS}
                      </a>
                    ),
                  }
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: undefined,
};

export default React.memo(Footer);
