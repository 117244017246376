// eslint-disable-next-line
import React, { useContext } from 'react';
import { ConfigContext } from '../contexts/ConfigContext';

const useRole = (roles = []) => {
  const { user } = useContext(ConfigContext);

  const is = (userParam, userRoles) => {
    if (!userRoles) {
      return true;
    }

    const authUser = userParam || user;

    if (!authUser) {
      return false;
    }

    if (!Array.isArray(userRoles)) {
      const can = authUser.role ? authUser.role.some(p => p === userRoles) : false;
      return can;
    }

    if (userRoles.length === 0) {
      return true;
    }

    return userRoles.every(p => authUser.role.includes(p));
  };

  return {
    is: userParam => {
      return is(userParam, roles);
    },
    isOwnerOrAdmin: (userParam, ownerId) => {
      const isAdmin = is(userParam, 'Admin');
      const isOwner = ownerId === userParam.id;
      return isOwner || isAdmin;
    },
    isOwner: (userParam, ownerId) => {
      return ownerId === userParam.id;
    },
  };
};

export default useRole;
