import React, { useContext } from 'react';
import Head from 'next/head';
import { useIntl } from 'react-intl';
import Layout1 from '../layout/layout1';
import Col from '../grid/col';
import Row from '../grid/row';
import Button from '../button/button';
import { useTitle } from '../../hooks';
import { ConfigContext } from '../../contexts/ConfigContext';
import errorStyles from './500.module.scss';

const Page500 = () => {
  const intl = useIntl();
  const { routeResolver } = useContext(ConfigContext);
  const { getTitle } = useTitle();

  return (
    <Layout1 lightBlue>
      <Head>
        <title>{getTitle(intl.formatMessage({ id: 'page.500.title' }))}</title>
      </Head>
      <Row span={12}>
        <Col span={6} startCol={4} spanMd={10} startColMd={2} spanSm={4} startColSm={1}>
          <div className={errorStyles.root}>
            <h2>{intl.formatMessage({ id: '500.header' })}</h2>
            <div className={errorStyles.info}>{intl.formatMessage({ id: '500.info' })}</div>
            <div className={errorStyles.actions}>
              <Button href={routeResolver.buildRouteFor(routeResolver.routes.index)} md>
                {intl.formatMessage({ id: 'error.common.back' })}
              </Button>
              <Button md link href="mailto:support@newzip.com" target="_blank">
                {intl.formatMessage({ id: 'error.common.support' })}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Layout1>
  );
};

export default Page500;
