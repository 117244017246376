export default {
  nftMintSubmitted: 'NFT Mint Submitted',
  nftMintIntent: 'NFT Mint Intent',
  nftMintCanceled: 'NFT Mint Canceled',
  nftAddressCopied: 'NFT Address Copied',
  openseaNFTLinkClicked: 'Opensea NFT Link Clicked',
  openseaUserLinkClicked: 'Opensea User Link Clicked',
  etherscanLinkClicked: 'Etherscan Link Clicked',
  showWeb3WalletAuthModal: 'Show Web3 Wallet Auth Modal',
  loadMoreRecentlyMinted: 'Load More Recently Minted',
  shareOnTwitter: 'Share on twitter',
};
