// eslint-disable-next-line
import React from 'react';
import { useIntl } from 'react-intl';

const useTitle = () => {
  const intl = useIntl();
  return {
    getTitle: text => {
      return intl.formatMessage(
        { id: 'page.title' },
        {
          text,
        }
      );
    },
  };
};

export default useTitle;
