import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import popupStyles from './popup.module.scss';

const Popup = ({ children, onClick, className, selector }) => {
  const ref = useRef();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line
    ref.current = document.querySelector(selector);
    setMounted(true);
  }, []);

  return mounted
    ? ReactDOM.createPortal(
        <div className={cn(popupStyles.root, className)}>
          <div className={popupStyles.content} onClick={onClick}>
            {children}
          </div>
          <div className={popupStyles.bg} />
        </div>,
        ref.current
      )
    : null;
};

export default Popup;

Popup.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  selector: PropTypes.string,
  onClick: PropTypes.func,
};

Popup.defaultProps = {
  className: undefined,
  selector: '#root-modal',
  onClick: () => {},
};
