import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import baseStyles from '../../styles/base.module.scss';

const Col = ({
  span,
  spanLg,
  spanMd,
  spanSm,
  spanXs,
  startCol,
  startColLg,
  startColMd,
  startColSm,
  startColXs,
  className,
  children,
}) => {
  return (
    <div
      className={cn(className, {
        [baseStyles[`span_${span}`]]: span,
        [baseStyles[`span_lg_${spanLg}`]]: spanLg,
        [baseStyles[`span_md_${spanMd}`]]: spanMd,
        [baseStyles[`span_sm_${spanSm}`]]: spanSm,
        [baseStyles[`span_xs_${spanXs}`]]: spanXs,
        [baseStyles[`col_${startCol}`]]: startCol,
        [baseStyles[`col_lg_${startColLg}`]]: startColLg,
        [baseStyles[`col_md_${startColMd}`]]: startColMd,
        [baseStyles[`col_sm_${startColSm}`]]: startColSm,
        [baseStyles[`col_xs_${startColXs}`]]: startColXs,
      })}
    >
      {children}
    </div>
  );
};

Col.defaultProps = {
  className: '',
  span: 1,
  spanLg: undefined,
  spanMd: undefined,
  spanSm: undefined,
  spanXs: undefined,
  startCol: undefined,
  startColLg: undefined,
  startColMd: undefined,
  startColSm: undefined,
  startColXs: undefined,
  children: undefined,
};

Col.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  span: PropTypes.number,
  spanLg: PropTypes.number,
  spanMd: PropTypes.number,
  spanSm: PropTypes.number,
  spanXs: PropTypes.number,
  startCol: PropTypes.number,
  startColLg: PropTypes.number,
  startColMd: PropTypes.number,
  startColSm: PropTypes.number,
  startColXs: PropTypes.number,
};

export default React.memo(Col);
