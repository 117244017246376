import { gql } from '@apollo/client';

export const typeDefs = gql`
  extend type Query {
    errors: Error!
  }

  type Error {
    key: String
    message: String
  }
`;

export const resolvers = {
  // Query: {
  //   Error: (parent, args, context, info) => {
  //
  //   }
  // }
};
