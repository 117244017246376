module.exports = {
  'page.title': '{text} - Sweetheartz',
  'page.index.title': 'Sweetheartz - From your heart. To their ETH wallet.',
  'page.index.description':
    'Sweetheartz is a non-fungible valentine. Mint directly to your valentine’s wallet or to your own.',
  'page.settings.title': '{text} - Settings',
  'page.settings.personalInfo.title': 'Personal info',
  'page.settings.loginAndSecurity.title': 'Login & security',
  'page.privacy.title': 'Privacy Policy',
  'page.terms.title': 'Terms of Use',
  'page.signUp.title': 'Sign up',
  'page.signIn.title': 'Sign in',
  'page.404.title': 'Page not found',
  'page.500.title': 'Ooops - No zip here',
  'imageUpload.incorrectFileType': 'File "{name}" not valid. Only {types} files allowed.',
  'imageUpload.incorrectFileSize': 'File "{name}" size exceeded. Maximum {size}mb allowed.',
  'imageUpload.maxSize': 'Max. {value}mb',
  'imageUpload.dragNDrop': 'Drag and drop image here',
  'common.continue': 'Continue',
  'common.confirm': 'Confirm',
  'common.public': 'Public',
  'common.private': 'Private',
  'common.published': 'Published',
  'common.copied': 'Copied',
  'common.publish': 'Publish',
  'common.draft': 'Draft',
  'common.back': 'Back',
  'common.edit': 'Edit',
  'common.addNew': 'Create new',
  'common.saved': 'Saved',
  'common.save': 'Save',
  'common.create': 'Create',
  'common.saveDraft': 'Save draft',
  'common.cancel': 'Cancel',
  'common.removed': 'Removed',
  'common.unpublished': 'Unpublished',
  'common.locked': 'Locked',
  'common.unlocked': 'Unlocked',
  'common.remove': 'Remove',
  'common.unpublish': 'Unpublish',
  'common.signIn': 'Sign In',
  'common.signUp': 'Sign Up',
  'common.input.limit': '{current}/{limit}',
  'common.free': 'Free',
  'common.loadMore': 'Load more',
  'common.gotIt': 'Got it',
  'common.seeDetails': 'See details',
  'common.selectItem': 'Select item',
  'common.multiAutocomplete': 'Type to search...',
  'common.logout': 'Log Out',
  'common.loggedAs': 'You are logged in as {name}',
  'common.map.newSpot': 'Create new spot',
  'common.footer.PP': 'Privacy policy',
  'common.footer.ToS': 'Terms of service',

  'common.validation.cryptoAddress': 'Wallet address is not valid',
  'common.validation.required': 'Required',
  'common.validation.email': 'Invalid email',
  'common.validation.password': 'Password should contain from 6 to 20 characters',
  'common.validation.passwordMatch': 'Password does not match',
  'common.validation.link': 'Link must start from https://',
  'common.validation.decimal': 'Invalid decimal',
  'common.validation.min': 'Cannot be less than {value}',
  'common.validation.minEqual': 'Should be bigger than {value}',
  'common.validation.max': 'Cannot be bigger than {value}',
  'common.validation.maxEqual': 'Should not exceed {value}',
  'common.validation.limitCharsTo': 'Should not exceed {value} chars',
  'common.validation.limitCharsFrom': 'Should exceed {value} chars',
  'common.validation.limitCharsFromTo': 'Should be between {from} and {to} chars',
  'common.validation.limitWordsFrom': 'Should contain more than {value} words',
  'common.validation.addressIsRequired': 'Wallet address is required',

  'clamp.seeMore': 'See more',
  'clamp.seeLess': 'See less',
  'getAccess.title': 'Get access',
  'getAccess.subTitle': 'Subscription includes',
  'getAccess.fee': 'Subscription fee',
  'getAccess.includeItem1': 'Full access to this Advisor’s content',
  'getAccess.includeItem2': 'Direct chat with this Advisor',
  'getAccess.includeItem3': 'Cancel your subscription at any time',
  'getAccess.cta': 'Subscribe',
  'getAccess.signIn.title': 'Sign in to continue',
  'getAccess.signIn.noAccount': "Don't have an account?",
  'getAccess.signIn.signUpCTA': 'Register',
  'getAccess.signIn.signInCTA': 'Sign in',
  'getAccess.signUp.title': 'Register to continue',
  'getAccess.signUp.haveAccount': 'Already have an account?',
  'getAccess.signUp.signInCTA': 'Sign in',
  'getAccess.signUp.signUpCTA': 'Register',
  'generalModals.success.title': 'Payment Completed',
  'generalModals.successFree.title': 'Order Completed',
  'generalModals.success.text': 'Your transaction was successful. Confirmation email was sent to {email}',
  'generalModals.success.cta': 'Done',
  'generalModals.serviceSuccess.title': 'Your online neighborhood session request is confirmed.',
  'generalModals.serviceSuccess.text': 'You will receive a calendar invite and an email with a link to your zoom/call.',
  'generalModals.error.title': 'Sorry!',
  'generalModals.error.text': 'Your transaction has failed. Please go back and try again.',
  'generalModals.error.cta': 'Okay',
  'datePicker.title': '{month} {year}',
  'datePicker.today': 'Today',
  'share.twitter': 'Twitter',
  'share.facebook': 'Facebook',
  'share.email': 'Email',
  'share.email.subject': 'Check out this session on Newzip!',
  'share.sms': 'SMS',
  'share.title': 'Share',
  'share.share': 'Share',
  'share.copy': 'Copy',
  'universalUpload.addAll': '+ Add photo or video',
  'universalUpload.addImage': '+ Add photo',
  'universalUpload.addVideo': '+ Add video',
  'universalUpload.size': '{size} MB',
  'universalUpload.video.incorrectFileSize': 'Video file "{name}" size exceeded. Maximum {size}mb allowed for video.',
  'universalUpload.image.incorrectFileSize': 'Image file "{name}" size exceeded. Maximum {size}mb allowed for image.',
  'wizard.step.desktop': '0{step}. {title}',
  'wizard.step.mobile': 'Step {step} of {length}',
  'index.title': 'From your heart.\nTo their ETH wallet.',
  'index.description':
    'Sweetheartz is a non-fungible valentine. Mint directly to your valentine’s wallet or to your own.',
  'index.mint.placeholder': "Paste your valentine's ENS name or ETH wallet address",
  'index.mint.connectToWallet': 'Connect wallet to mint',
  'index.mint.userAddress': 'Connected: <elem>{address}</elem>',
  'index.mint.disconnect': 'Disconnect',
  'index.mint.text.label': 'Send it to',
  'index.mint.price': 'Price: <elem>{price} ETH</elem>',
  'index.mint.price.free': 'Price:<elem>Free</elem>',
  'index.mint.price.freeSm': 'Price: <oldPriceElem>{price} ETH</oldPriceElem> <elem>Free #{maxFreeCount}</elem>',
  'index.mint.admin': 'Logged in as Admin. Minting is free.',
  'index.mint.freeTokensLeft': '{count} Free tokens left',
  'index.mint.freeTokenAlreadyMinted': 'Free token already minted',
  'index.mint.sendToValentine': 'Send to your Valentine',
  'index.mint.sendToValentine.short': 'Send',
  'index.mint.mintYourWallet': 'Mint to your wallet',
  'index.mint.mintYourWallet.short': 'Mint to your wallet',
  'index.mint.wrongNetwork':
    'You are currently connected to a different network.\nPlease change your wallet network to {network}.',
  'index.mint.cta': 'Mint Sweetheart',
  'index.mint.mintInProgress': 'Minting in progress...',

  'index.sendSweetheartz.title': 'Send your Sweetheartz.',
  'index.sendSweetheartz.description':
    'Sweetheartz is a collection of 10,000 unique hearts with over 100 different Web3 slang messages on them. The digital version of the Valentine’s candy we all know and love come in 45 colors, 40 backgrounds and include 11 different accessories. They’re designed specifically for sending to that special someone. Mint directly to your Valentine’s Ethereum wallet. Or treat yo’ self and mint to your own wallet.',
  'index.sendSweetheartz.openSea': 'View on OpenSea',
  'index.getSomethingUnique.title': 'Get something unique.',
  'index.getSomethingUnique.item1': '100 Web3 Messages',
  'index.getSomethingUnique.item2': '45 Heart Colors',
  'index.getSomethingUnique.item3': '25 Background Colors',
  'index.getSomethingUnique.item4': '10 Accessories',
  'index.faq.title': 'FAQ',
  'index.faq.question1': 'Who is behind Sweetheartz?',
  'index.faq.answear1':
    'We’re Jayme, Mike, Krishna, Antoni and Yaroslav, the team behind Launchcaster & Maindrop (YC W21). We’re interested in the intersection of NFTs and virtual gifts. That’s why we created Sweetheartz to be shared rather than collected.',
  'index.faq.question2': 'What exactly is an NFT?',
  'index.faq.answear2':
    'An NFT is a non-fungible token, which means that it’s one-of-a-kind digital asset stored on the blockchain (i.e. Ethereum). OpenSea has <link>{link1}</link> and <link>{link2}</link> has <link>{link3}</link>',
  'index.faq.answear2.link1': 'a great guide to NFTs here',
  'index.faq.answear2.link2': 'Blake Robbins',
  'index.faq.answear2.link3': 'a good explanation of how things come together in Web3.',
  'index.faq.question3': 'How unique will each Sweetheartz be?',
  'index.faq.answear3':
    'Each Sweetheartz is unique (1 of 1). They include different messages, heart colors, background colors/textures and accessories. There will be at most 10,000 different variations of Sweetheartz.',
  'index.faq.question4': 'Where are the Sweetheartz images and metadata stored?',
  'index.faq.answear4':
    'Sweetheartz images and metadata are stored on IPFS. We use <link>{link1}</link>, a free decentralized storage and bandwidth for NFTs on IPFS and Filecoin.',
  'index.faq.answear4.link': 'NFT Storage',
  'index.faq.question5': 'What wallets does Sweetheartz support?',
  'index.faq.answear5': 'We support Metamask and WalletConnect which supports the top 100 wallets ✊',
  'index.faq.question6': 'What happens when I mint to my Valentine’s wallet?',
  'index.faq.answear6':
    'The Sweetheartz NFT will be minted to the wallet address you provide. The wallet owner will be able to see it when they connect to OpenSea, Rainbow and other Ethereum wallets. ℹ️ Note - You should notify your valentine that you sent them an NFT since most wallets do not notify users of new NFTs or Airdrops.',
  'index.faq.question7': 'Can I sell my Sweetheartz after I mint it or receive it from an Ex-lover?',
  'index.faq.answear7': 'It’s your NFT forever. You can sell it or re-gift it whenever you want.',
  'index.faq.question8': 'What happens with royalties from secondary sales?',
  'index.faq.answear8': 'Sweetheartz do not have royalties on secondary sales. Only love ❤️',
  'index.faq.question9': 'Do you have a discord? What’s the roadmap?',
  'index.faq.answear9':
    'We don’t currently have a Discord or a roadmap. We honestly just wanted a fun NFT valentine to send to our friends and loved ones. The more we work on it the more we find ourselves wanting better ways to mint to friends wallets, sweetheartz shirts, stickers and more... so the fun doesn’t stop here. <link>Please tweet at us if you think we should start a Discord!</link>',
  'index.faq.answear9.link1': 'tweet',
  'index.faq.answear9.tweet': 'wen @SweetheartzNFT discord?',

  'details.label': 'Token details',
  'details.title': 'Sweetheartz #{tokenId}',
  'details.minting': 'Minting in progress...',
  'details.loading': 'Loading...',
  'details.user': 'Owned by <btn>{address}</btn>',
  'details.cta.bidHistory': 'View on OpenSea',
  'details.cta.etherscan': 'View on Etherscan',
  'details.pagination': '{tokenId}/{total}',
  'details.copy.message': 'You minted love on the blockchain! Share your Sweetheartz via twitter and text',
  'details.copy.cta': 'Copy',
  'details.copy.share': 'Tweet Sweetheartz',
  'details.copy.shareMessage': 'I minted a @SweetheartzNFT on the blockchain for Valentine’s Day 💝 ',

  '404.header': 'Error. Please refresh.',
  '404.info':
    'Something went wrong or the page you’re looking for was not found. If you’re minting, please try to refresh.',
  '500.header': 'Oops - Something went wrong',
  '500.info': 'Please try again later or feel free to contact support about this problem.',
  'error.common.back': 'Go back home',
  'error.common.refresh': 'Refresh page',
  'error.common.support': 'Contact support',

  'footer.copyright': 'Copyright Sweetheartz {year} ©',
  'footer.twitter': 'SweetheartzNFT',
  'status.viewOpenSea': 'View on OpenSea',
  'status.mint': 'Mint Sweetheart',
  'status.connectToWallet': 'Connect wallet to mint',
  'status.available': '{available} of 10,000 available!',
  'status.verified': 'Verified Smart Contract: {link}',
  'status.loadMore': 'Load more',
  'status.title': 'Recently minted',
};
