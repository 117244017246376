import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import notificationModalStyles from './notificationModal.module.scss';
import Modal from './modal';
import Button from '../button/button';
import Icon from '../icon/icon';

const NotificationModal = ({ show, title, text, type, ctaText, ctaOnClick, onClose }) => {
  return (
    <Modal visible={show} onClose={onClose} showMode="Center">
      <div className={notificationModalStyles.root}>
        <div className={notificationModalStyles.header}>
          <div className={cn(notificationModalStyles.type, [notificationModalStyles[type]])}>
            {type === 'success' && <Icon lg type="icon-status-check" />}
            {type === 'error' && <Icon lg type="icon-status-error" />}
          </div>
          <div className={notificationModalStyles.title}>{title}</div>
        </div>
        <div className={notificationModalStyles.text}>{text}</div>
        <div className={notificationModalStyles.cta}>
          <Button md block onClick={ctaOnClick}>
            {ctaText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NotificationModal;

NotificationModal.propTypes = {
  ctaOnClick: PropTypes.func.isRequired,
  ctaText: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
};

NotificationModal.defaultProps = {
  type: undefined,
  onClose: () => {},
  text: undefined,
  title: undefined,
};
