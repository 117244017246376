import createDataContext from './createDataContext';

const initialState = {
  totalSupply: undefined,
  supplyUsed: undefined,
  canFreeMint: false,
  freeMintLimitReached: false,
  freeMintTokensLeft: 0,
  authUserCanMintFree: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'set_total_supply': {
      return {
        ...state,
        totalSupply: action.payload,
      };
    }

    case 'set_auth_user_can_mint_free': {
      return {
        ...state,
        authUserCanMintFree: action.payload,
      };
    }

    case 'set_free_mint_limit_reached': {
      return {
        ...state,
        freeMintLimitReached: action.payload,
      };
    }

    case 'set_free_mint_tokens_left': {
      return {
        ...state,
        freeMintTokensLeft: action.payload,
      };
    }

    case 'set_supply_used': {
      return {
        ...state,
        supplyUsed: action.payload,
      };
    }

    default:
      return state;
  }
};

const setTotalSupply = ({ dispatch }) => {
  return async totalSupply => {
    dispatch({ type: 'set_total_supply', payload: totalSupply });
  };
};

const setSupplyUsed = ({ dispatch }) => {
  return async supplyUsed => {
    dispatch({ type: 'set_supply_used', payload: supplyUsed });
  };
};

const setAuthUserCanMintFree = ({ dispatch }) => {
  return async canMintFree => {
    dispatch({ type: 'set_auth_user_can_mint_free', payload: canMintFree });
  };
};

const setFreeMintLimitReached = ({ dispatch }) => {
  return async limitReached => {
    dispatch({ type: 'set_free_mint_limit_reached', payload: limitReached });
  };
};

const setFreeMintTokensLeft = ({ dispatch }) => {
  return async tokensNumber => {
    dispatch({ type: 'set_free_mint_tokens_left', payload: tokensNumber });
  };
};

export const { Provider, Context } = createDataContext(
  reducer,
  {
    setSupplyUsed,
    setTotalSupply,
    setAuthUserCanMintFree,
    setFreeMintLimitReached,
    setFreeMintTokensLeft,
  },
  { ...initialState }
);
